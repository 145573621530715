import Alpine from 'alpinejs'
import "bootstrap";
import "htmx.org";

window.Alpine = Alpine
console.log("active url",  window.location.pathname);

Alpine.data('sidebarmenu',(initActiveEl = window.location.pathname)=>({
    activeEl : initActiveEl,
    openItems: {
        'verifyFund': true,
        'download': true
    }
}))
Alpine.start()
